<template>
  <div>

    <!-- 表格 -->
    <div class="res-open-content type-lhc twlh">
      <div class="r-o-lhc">
        <table class="com-table">
          <tr>
            <th rowspan="2">期数</th>
            <th rowspan="2">时间</th>
            <th rowspan="2">正码一</th>
            <th rowspan="2">正码二</th>
            <th rowspan="2">正码三</th>
            <th rowspan="2">正码四</th>
            <th rowspan="2">正码五</th>
            <th rowspan="2">正码六</th>
            <th rowspan="2">特码</th>
            <th colspan="5">总和</th>
            <th colspan="5">特码</th>
          </tr>
          <tr>
            <th>总数</th>
            <th>单双</th>
            <th>大小</th>
            <th>七色波</th>
            <th>总肖</th>
            <th>单双</th>
            <th>大小</th>
            <th>合单双</th>
            <th>合大小</th>
            <th>大小尾</th>
          </tr>
          <tr v-for="item in list" :key="item.Expect">
            <td>{{item.Expect}}</td>
            <td>{{item.Opentime|substrSome}}</td>
            <td v-for="(code,i) in item.codeArr" :key="code" class="td-ball">
              <div class="lhc-ball">
                <p :class="'blueball b'+code"></p>
                <span>{{item.sxArr[i]}}</span>
              </div>
            </td>

            <td :class="fontColor[n]" v-for="(n,index) in item.lutuResult" :key="index">{{n}}</td>

          </tr>
        </table>
        <div class="page">
          <!-- :page-sizes="[20,50,100,200]" -->
          <el-pagination @current-change="handleCurrentChange" :current-page="pages.pagenum" :page-size="pages.pagecount" layout="total, prev, pager, next, jumper" :total="totals">
          </el-pagination>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import kjmixins from "../mixins/kjmixins";
export default {
  name: "kjlhc",
  props: [""],
  mixins: [kjmixins],
  data() {
    return {};
  },

  components: {},
  created() {},
  computed: {},

  methods: {},
};
</script>
<style  scoped>
</style>